import WebLinks from "./WebLinks";
import CookieService from "../Service/CookieService";
import CookieList from "../Service/CookieList";
import { Redirect,useHistory  } from "react-router-dom";
import { browserHistory } from 'react-router';
import AuthService from "../Service/AuthService";
import {
  message
} from "antd";


const expiresAt = 60 * 24;
class APIUtils {

  // static apiCall = async (method, webLink, payload=null, isAuth = true) => {
  //   let headerContent = {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //   };
  //   if (isAuth) {
  //     let token = "Bearer " + CookieService.get(CookieList.user_token);
  //     if (token == null || token === "") {
  //       //TODO: redirect to LOGIN
  //     }
  //     headerContent["Authorization"] = token;
  //   }
  //   console.log("header content", headerContent);
  //   if (method === "GET") {
  //     return fetch(webLink, {
  //       method: method,
  //       headers: headerContent,
  //     })
  //       .then((res) => res.json())
  //       .then((result) => {
  //         console.log("RESPONSE", result);
  //         if (result === "unauthorized") {
  //           //TODO: redirect to login page
  //         } else {
  //           return result;
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("RESPONSE_error", error);
  //         return error;
  //       });
  //   } else {
  //     return fetch(webLink, {
  //       method: method,
  //       headers: headerContent,
  //       body: payload,
  //     })
  //       .then((res) => res.json())
  //       .then((result) => {
  //         console.log("RESPONSE", result);
  //         if (result === "unauthorized") {
  //           //TODO: redirect to login page
  //           console.log("UNAUTH");
  //         } else {
  //           return result;
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("RESPONSE_error", error);
  //         return error;
  //       });
  //   }
  // };
  // method created to include the catch handle response


  static apiCall = async (method, webLink, payload=null, isAuth = true) => {
    let responseObj={"resultObj":null,"isCatch":false};
    let headerContent = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (isAuth) {
      let token = "Bearer " + CookieService.get(CookieList.user_token);
      if (token == null || token === "") {
        //TODO: redirect to LOGIN
        window.location.href = "/";
      }
      headerContent["Authorization"] = token;
    }
    if (method === "GET") {
      return fetch(webLink, {
        method: method,
        headers: headerContent,

      })
        .then((res) =>res.statusText !== "Unauthorized"||isAuth==false?res.json():res)
        .then((result) => {
          if (result.statusText === "Unauthorized") { //|| fetchClients.error === "failed" || fetchClients==="unauthorized"
            //TODO: redirect to login page
             CookieService.removeAllCookies();
            message.warning('Session Expired',3);
            alert('Your login has expired. Please logoff and login again.')
            window.location.href = "/"
          } else {
            responseObj.resultObj=result;
            responseObj.isCatch=false;
            return responseObj;
          }
        })
        .catch((error) => {
          responseObj.isCatch=true;

          return responseObj;
        });
    } else {
      return fetch(webLink, {
        method: method,
        headers: headerContent,
        body: payload,
      })
        .then((res) => res.statusText !== "Unauthorized" ||isAuth==false?res.json():res)
        .then((result) => {
          if (result.statusText === "Unauthorized") {
            //TODO: redirect to login page
            CookieService.removeAllCookies();
            message.warning('Session Expired');
            alert('Your login has expired. Please logoff and login again.')
            window.location.href = "/";
            
          } else {
            responseObj.resultObj=result;
            responseObj.isCatch=false;
            return responseObj;
          }
        })
        .catch((error) => {
         responseObj.isCatch=true;
          return responseObj;
          
        });
    }
  };

  static Token = (userData, isRemember, password, iternalUser=false) => {
    let pass=iternalUser?userData.code:password;
    return fetch(WebLinks.TokenLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body:
        "grant_type=password&username=" +
        userData.user_id +
        "&password=" +
        pass,

    })
      .then((res) => res.json())
      .then((result) => {
         //TODO: change the status code
        if (result.error === "failed" || result==="unauthorized") {
          return false;
        }else{
          let options = "";
        if (!isRemember) {
          options = { path: "/" };
        } else {
          let date = new Date();
          date.setTime(date.getTime() + expiresAt * 60 * 1000);  //result.expires_in//TODO: add the expiry from API 
          options = { path: "/", expires: date };
        }
        CookieService.set(CookieList.id, userData.id, options);
        CookieService.set(CookieList.user_name, userData.user_id, options);
        CookieService.set(CookieList.user_token, result.access_token, options);
        CookieService.set(CookieList.user_first_name, userData.first_name, options);
        CookieService.set(CookieList.user_email, userData.email, options);
        CookieService.set(CookieList.user_permissions, userData.permissions, options);
        return true;
        }
      })
      .catch((error) => {
        return false;
      });
  };

  HandleCookies() { }
}
export default APIUtils;
