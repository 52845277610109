import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import * as wjcCore from '@grapecity/wijmo';

wjcCore.setLicenseKey('app.profitechnologies.com|budgetapp.provisionllc.com|provisionuatapp.eastus.cloudapp.azure.com,463883143221121#B0tQZMzgDOzYDNiojIklkIs4XXbpjInxmZiwiIzYXMyAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TUtxESrUkbOZTNGV6T93iMxcFejdGeoFmdCNkaypHSB9kWjRXRM5kZztEOIF6SEhlN4R4a4d4Z9BDNUVURDV4a4JkZ9tmRLNmMphVdqRzVwlVNMljWvFEejhzYUN4ZO9UWxAHM9Zzcs3EdotkSwIUauVDa8UHcrQ7boJ5aSFUQxkXNYJnUrFXUGhjWwhkYzoWbC5mYmNWYKhHUX9UcvwkZqdFZTJ7UxcGVZhmYOdHVMFXVx24QHpHToV6UaNUShdGUwBVdrxWSLRzcll5cBNnN7VjY6R5KvcFMnllVVJWeOp4KEd7K9g5SqBldmhzM7cDa9IUNod7VnN7Y9FVM9gFT7J7VYBTWnV7c0N6SOZXc7lGR094c6l7KHpUYld5VmdHcB9GWx3GR8g4Zo5EWCllWNlVYXVTRSZGTpx6UQFTRpZjT8wkc9Yjd9tUOCtCM5c7TB5GTQZlI0IyUiwiI5YkRDBTO5YjI0ICSiwCNxkTOwQDM5YTM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiAzM5ITNwACMzQDMyIDMyIiOiQncDJCLi46bj9SZyVneh9CcwFGZ53Gbj9yc5R7chVmLwBXY4FWdu3WazlmdvJHcs46bj9yYsxmbvl6cpZ7byBnLwBXY4V6ZkVnYs46bj9ycll6Zvx6buh6YlRXam3mcw9CcwFmI0IyctRkIsIyQMxEIzV6YpZnclNFI9J7bzlmdkFEIu3WazlmdvJHUiojIh94QiwiIxITMJIXM');
export default class App extends React.Component {
  componentDidMount(){
    document.title = process.env.REACT_APP_TITLE
  }
  render() {
    return (
      <Router>
        <Routes/>
      </Router>
    );
  }
}
