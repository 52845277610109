import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {
  Spin
} from "antd";

ReactDOM.render(
    // <React.StrictMode>
    <React.Suspense fallback={<Spin style={{height:"100%",width:"100%"}} spinning={true} tip="Loading..."></Spin>}>
    <App />
  </React.Suspense>
    // </React.StrictMode>
    ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
