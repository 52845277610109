import CookieService from "../Service/CookieService";
import CookieList from "./CookieList";
import Utils from "../API/APIUtils";
import WebLinks from "../API/WebLinks";
import PermissionList from "./PermissionList";
class AuthService {
  constructor() {
    this.authenticated = CookieService.get(CookieList.user_token)
      ? true
      : false;
  }

  login() {
    this.authenticated = true;
  }

  // unAuthorisedRedirect(){
  //   const history=useHistory();
  //   CookieService.removeAllCookies();
  //   history.push("/");
  // }

  async logout() {
    //TODO: api call to logout
    const logoutResult = await Utils.apiCall(
      "POST",
      WebLinks.LogoutLink,
      CookieService.get(CookieList.user_token)
    );

    if(logoutResult && logoutResult.resultObj){
      if (logoutResult.resultObj.Code === 209) {
        //CookieService.removeList(CookieList.toClear);
        CookieService.removeAllCookies();
        CookieService.remove(CookieList.user_token);
      }
    }
  }
  isAuthenticated() {
    return this.authenticated; //CookieService.get(CookieList.user_token) ? true : false;
  }
  //PermissionType: pass the permission type from PermissionList
  // isUserPermitted(PermissionType) {
  //   console.log(" permission type",PermissionType);
  //   let response = {
  //     permission_list: null,
  //     access: false,
  //   };
  //   if (PermissionType === PermissionList.no_permission) {
  //     response = {
  //       permission_list: null,
  //       access: true,
  //     };
  //     return response;
  //   } else {
  //     const permissionsList = CookieService.get(CookieList.user_permissions);
  //     permissionsList.map((permission) => {
  //       console.log("per mission", permission);
  //       if (permission.feature == PermissionType) {
  //         response = {
  //           permission_list: permission.permissions,
  //           access: true,
  //         };
  //         console.log("response::", response);
  //         return response;
  //       }
  //     });
  //   }
  //   console.log("response after", response);
  //   return response;
  // }

  //PermissionType | SubPermissionType : pass the permission type from PermissionList
  //subType: true:if the permission is under a feature
  isPermitted(PermissionType, SubPermissionType = null, subType = false) {
    let response = false;
    if (
      String(PermissionType).toLowerCase ===
      String(PermissionList.no_permission).toLowerCase
    ) {
      response = true;
    } else {
      const permissionsList = CookieService.get(CookieList.user_permissions);
      permissionsList.map((permission) => {
        if (
          String(permission.feature).toLowerCase ===
          String(PermissionType).toLowerCase
        ) {
          if (subType) {
            permission.permissions.map((subpermissions) => {
              if (
                String(subpermissions.name).toLowerCase ===
                String(SubPermissionType).toLowerCase
              ) {
                response = true;
              }
            });
          } else {
            response = true;
          }
        }
      });
    }
    return response;
  }

  isTabAccessPermission(PermissionType, PermissionTabList) {
    let responseVal = false;
    const permissionsList =
      PermissionTabList != null ? PermissionTabList : null;
    if (permissionsList == null || permissionsList === "undefined") {
      return (responseVal = false);
    } else {
      permissionsList.tabs.map((permission) => {
        if (
          String(permission.name).toLowerCase() ===
          String(PermissionType).toLowerCase()
        ) {
          return (responseVal = true);
        }
      });
    }
    return responseVal;
  }

  isFeatureAccessPermission(
    PermissionType,
    PermissionTabList,
    isfeature = false
  ) {
    let responseVal = false;
    //console.log('isfeature',isfeature)
    const permissionsList =
      PermissionTabList != null ? PermissionTabList : null;
    if (permissionsList == null || permissionsList === "undefined") {
      return (responseVal = false);
    } else {
      if (isfeature === true) {
        permissionsList.map((permission) => {
          if (
            String(permission.feature).toLowerCase() ===
            String(PermissionType).toLowerCase()
          ) {
            return (responseVal = true);
          }
        });
      } else {
        permissionsList.map((permission) => {
          if (
            String(permission.name).toLowerCase() ===
            String(PermissionType).toLowerCase()
          ) {
            return (responseVal = true);
          }
        });
      }
    }
    return responseVal;
  }
}
export default new AuthService();
