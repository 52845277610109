const PermissionList = {
  no_permission:'no permission required',
  //Common permissions
  View:'View',
  Edit:'Edit',
  Create:'Create',
  Delete:'Delete',
  Save:'Save',
  Approve:'Approve',
  //Access Controls
  //Master
  DisciplineName: 'Discipline Name',
  CurrencyName:'Currency Name',
  Code:'Code',
  Symbol:'Symbol',
  DocumentType:'Document Type',
  Source:'Source',
  Areas:'Areas',
  AreaName: 'Area Name',
  BulkUploads:'Bulk Uploads',
  TaxType:'Tax Type',
  Rate:'Rate(%)',
  ProductCategoryName:'Product Category Name',
  BusinessUnitType:'Business Unit Type',
  ProductSubCategory: 'Product Sub-Category',
  FinishCategoryTypeName: 'Finish Category Type Name',
  FinishCategoryName: 'Finish Category Name',
  VendorType: 'Vendor Type',
  Division: 'Division',
  Comment: 'Comment',
  Name: 'Name',
  Score:"Score",
  Status:'Status',
  Action:'Action',
  SaveSelection:"Save Selection",
  //user
    user:'User administration',
    user_view:'View users',
    user_create:'Create users',
    user_edit:'Edit users',
    user_delete:'Delete users',
    user_permission:'Assign permissions',
    user_verify:'Verify users',
    //audit
    audit:'Audit log',
    audit_view:'View audit log',
    //master
    master:'Master data administration',
    master_view:'View Masters',
    master_create:'Create Masters',
    master_edit:'Edit Masters',
    master_delete:'Delete Masters',
    //client
    client:'Client administration',
    client_edit:'Edit', 
    client_create:'Create', 
    client_view:'View', 
    client_delete:'Delete',
    client_create_pqf:'Create PQF',
    operation_client_tab:'Client Listing',
    //vendor
    vendor:'Vendor administration',
    vendor_edit:'Edit Vendors',
    vendor_delete:'Delete Vendors',
    vendor_create:'Create Vendors',
    vendorDataTab_file_uploaded:'File Uploaded',
    vendorDataTab_allowances:'Allowances',
    masterDataTab_allowances:'Allowance Agreement',
    vendorDataTab_vendor_pqq:"Vendor PQQ",
    vendorDataTab_details:"Details",
    vendorDataTab_view:'View',
    vendorDataTab_edit:'Edit',
    vendorDataTab_create:'Create',
    vendorDataTab_delete:'Delete',
    vendorDataTab_download:'Download',
    vendorDataTab_field_document_type:'Document Type',
    vendorDataTab_field_document_type_source:'Document Type Source',
    vendorDataTab_field_uploaded_by:'Uploaded By',
    vendorDataTab_field_uploaded_on:'Uploaded On',
    vendorDataTab_field_file_size:'File Size',
    vendorDataTab_field_action:'Action',
    //project
    project:'Project Management',   
    project_create:'Create', 
    //BOQ
    BOQ:'BoQ Management',
    budget:'Budget Management', 
    report:'Report Management',   
    // report:'Report Management',   
    permission_list_test:'permissionList',
    //Master Data Permissions
    //Master Data tab Permissions
    masters_masterdata:'Master Data Administration',
    masters_templates:'Template Administration',
    masters_discipline_tab:'Discipline',
    masters_area_tab:'Area',
    masters_tendercategory_tab:'Product Category',
    masters_subtendercategory_tab:'Product Sub-Category',
    masters_finishtypecategory_tab:'Finish Category',
    masters_finishtype_tab:'Finish Category Type',
    masters_currency_tab:'Currency',
    masters_documenttype_tab:'Document Type',
    masters_taxtype_tab:'Tax Type',
    masters_pqq_tab:'PQQ',
    masters_vendermatrix_tab:'Vendor Scoring Matrix',
    masters_pqq_view:'View',
    masters_pqq_edit:'Edit',
    masters_venderscoring_edit:'Edit',
    masters_venderscoring_view:'View',
    masters_formulaBuilder_tab:'Formula Builder',
    masters_formulaBuilder_view:'View',
    masters_formulaBuilder_edit:'Edit',
    //Vendor Administration
    vendor_administration: 'Vendor Administration',
    //Operations Data Permissions
    //OperationsData tabs permissions
    operations_operator:'Operator Administration',
    operations_designer:'Designer Administration',
    operations_enquiry:'Enquiry Management',
    operations_client:'Client Administration',
    operations_divisions:'Division Management',
    operations_user:'User Administration',
    operations_project:'Project Management',
    operations_vendors:'',
    operations_enquiryForm:'Enquiry Form',
    //Operations operator permissions
    operation_operator_tab:'Operator',
    //Operations designer permissions
    operation_designer_tab:'Designer',
    //Enquiry tab permission
    operation_enquiry_tab_enquiry:'Enquiry',
    operation_enquiry_tab_enquiryform:'Enquiry Form',
    //Enquiry permissions
    operations_enquiry_view:'View',
    operations_enquiry_delete:'Delete',
    operations_enquiry_updatestatus:'Update Status',
    //operations division permissions
    operation_division_tab:'Division',
    operation_division_create:'Create',
    operation_division_view:'View',
    operation_division_delete:'Delete',
    operation_division_edit:'Edit',
    //operations user permissions
    operation_user_listing_tab:'User Listing',
    operation_user_roles_tab:'Roles and Permissions',
    operation_user_verification_tab:'User Verification',
    operation_user_listing_create:'Create',
    operation_user_listing_delete:'Delete',
    operation_user_listing_edit:'Edit',
    operation_user_listing_view:'View',
    operation_user_verification_view:'View',
    operation_user_verification_verify:'Verify',
    operation_user_roles_edit:'Edit',
    operation_user_roles_saveas:'Save As',
    operation_user_roles_view:'View',
    //operation project permissions
    operation_project_details_tab:'Details',
    operation_project_taxes_tab:'Taxes',
    operation_project_categoryMarkup_tab:'Category Markup ',
    operation_project_fileUploads_tab:'File Uploads',
    operation_project_specfication_tab:'Specifications',
    operation_project_projectlist:'Project List',
    // project_details_create:'Create',
    // project_details_view:'View',
    // project_details_delete:'Delete',
    // project_details_edit:'Edit',
    project_fileUploads_upload:'Upload',
    project_fileUploads_view:'View',
    // project_taxes_create:'Create',
    // project_taxes_view:'View',
    // project_taxes_delete:'Delete',
    // project_taxes_edit:'Edit',
    project_taxes_column_taxtype:'Tax Type',
    project_taxes_column_rate:'Rate(%)',
    project_taxes_column_action:'Action',
    // project_categoryMarkup_create:'Create',
    // project_categoryMarkup_view:'View',
    // project_categoryMarkup_delete:'Delete',
    // project_categoryMarkup_edit:'Edit',
    project_categoryMarkup_productSub:'Product Sub-Category',
    project_categoryMarkup_markup:'Markup %',
    project_categoryMarkup_cif:'CIF %',
    project_categoryMarkup_installation:'Installation %',
    project_categoryMarkup_duty:'Duty %',
    project_categoryMarkup_clearance:'Clearance & Delivery %',
    project_categoryMarkup_warehouse:'Warehouse %',
    project_categoryMarkup_other:'Other %',
    project_categoryMarkup_action:'Action',
    project_specification_create:'Create',
    project_specification_createVersion:'Create Versions',
    project_specification_delete:'Delete',
    project_specification_edit:'Edit',
    project_specification_saveMarkup:'Save Markup',
    project_specification_UploadSpec:'Upload Spec',
    project_specification_view:'View',
    project_specification_viewHistory:'View History',
    project_specification_name:'Name',
    project_specification_specification:'Specification',
    project_specification_specid:'Spec id',
    project_specification_productsubCategory:'Product Sub-Category',
    project_specification_canVendorPrice:'Can Vendor Price?',
    project_specification_canVendorQuantify:'Can Vendor Quantify?',
    project_specification_brand:'Brand/Mfr.',
    project_specification_distributor:'Distributor',
    project_specification_action:'Action',
    project_specification_finishtype:'Finish Type',
    project_specification_uom:'uom_id',
    //ProjectMenu tabs
    projectMenuTab_pqfFFNE:'PQF-FF&E',
    projectMenuTab_pqfOSNE:'PQF-OS&E',
    projectMenuTab_rfp:'RFP',
    projectMenuTab_analysis:'Analysis',
    projectMenuTab_vendor:'Vendor Administration - Client',
    projectMenuTab_details:'Project Information',
    projectMenuTab_pqf_drawings:'Drawing & Layouts',
    projectMenuTab_pqf_ffefileUploads:'FF&E File Uploads',
    projectMenuTab_pqf_specificationNfinishes:'Specification & Finishes',
    projectMenuTab_pqf_drawings_view:'View',
    projectMenuTab_pqf_drawings_upload:'Upload',
    projectMenuTab_pqf_specificationNfinishes_view:'View',
    projectMenuTab_pqf_specificationNfinishes_upload:'Upload',
    projectMenuTab_pqf_osne_listTemplates:'List of Templates for Approval',
    projectMenuTab_pqf_osefileUploads:'OS&E File Uploads',
    projectMenuTab_pqf_osne_restaurents:'F&B Restaurents',
    projectMenuTab_pqf_osne_banquets:'Banquets',
    projectMenuTab_pqf_osne_areas:'Recreational Areas',
    projectMenuTab_pqf_osne_others:'Others',
    projectMenuTab_pqf_osne_questionaire_save:'Save',
    projectMenuTab_pqf_osne_questionaire_view:'View',
    projectMenuTab_pqf_osne_templateList_upload:'Upload',
    projectMenuTab_pqf_osne_templateList_view:'View',
    projectMenuTab_pqf_osne_fileuploads_view:'View',
    projectMenuTab_details_prequaliication:'Property Questionnaire Form',
    projectMenuTab_details_billshipaddress:'Bill To/Ship To Address',
    projectMenuTab_details_details:'Stakeholder Information',
    projectMenuTab_project_logistics:'Project Logistics',
    projectMenuTab_details_inputMatrix:'Input Matrix',
    projectMenuTab_details_prequaliication_view:'View',
    projectMenuTab_details_prequaliication_save:'Save',
    projectMenuTab_details_billshipaddress_view:'View',
    projectMenuTab_details_billshipaddress_save:'Save',
    projectMenuTab_details_inputMatrix_view:'View',
    projectMenuTab_details_inputMatrix_save:'Save',
    projectMenuTab_details_inputMatrix_roomType:'Room Type Name',
    projectMenuTab_details_inputMatrix_livingCount:'Living Count',
    projectMenuTab_details_inputMatrix_kitchenCount:'Kitchen Count',
    projectMenuTab_details_inputMatrix_bedCount:'Bed Count',
    projectMenuTab_details_inputMatrix_bathCount:'Bath Count',
    projectMenuTab_details_inputMatrix_powderCount:'Powder Count',

    projectMenuTab_details_inputMatrix_superKing:'Super King',
    projectMenuTab_details_inputMatrix_king:'King',
    projectMenuTab_details_inputMatrix_queen:'Queen',
    projectMenuTab_details_inputMatrix_fullDouble:'Full/Double',
    projectMenuTab_details_inputMatrix_twinXL:'Twin XL',
    projectMenuTab_details_inputMatrix_twin:'Twin',
    projectMenuTab_details_inputMatrix_sofaBed:'Sofa Bed',
    projectMenuTab_details_inputMatrix_comments:'Comments',


    projectMenuTab_details_inputMatrix_units:'Units',
    projectMenuTab_details_inputMatrix_action:'Action',
    projectMenuTab_details_inputMatrix_bedType:'Bed Type',
    projectMenuTab_details_inputMatrix_outletName:'Outlet Name',
    projectMenuTab_details_inputMatrix_outletType:'Outlet Type',
    projectMenuTab_details_inputMatrix_coverChanirs:'Covers/Chairs',
    projectMenuTab_details_details_view:'View',
    projectMenuTab_details_details_save:'Save',
    projectMenuTab_boq:'BoQ Management',
    projectMenuTab_boq_Listing:'BOQ Listing',
    projectMenuTab_boq_specification:'Specifications',
    projectMenuTab_boq_management:'FF&E BoQ Details',
    projectMenuTab_boq_specification_create:'Create',
    projectMenuTab_boq_specification_createVersion:'Create Versions',
    projectMenuTab_boq_specification_delete:'Delete',
    projectMenuTab_boq_specification_edit:'Edit',
    projectMenuTab_boq_specification_saveMarkup:'Save Markup',
    projectMenuTab_boq_specification_UploadSpec:'Upload Spec',
    projectMenuTab_boq_specification_view:'View',
    projectMenuTab_boq_specification_viewHistory:'View History',
    projectMenuTab_boq_specification_name:'Name',
    projectMenuTab_boq_specification_specification:'Specification',
    projectMenuTab_boq_specification_specid:'Spec id',
    projectMenuTab_boq_specification_productsubCategory:'Product Sub-Category',
    projectMenuTab_boq_specification_canVendorPrice:'Can Vendor Price?',
    projectMenuTab_boq_specification_brand:'Brand/Mfr.',
    projectMenuTab_boq_specification_distributor:'Distributor',
    projectMenuTab_boq_specification_action:'Action',
    projectMenuTab_boq_specification_finishtype:'Finish Type',
    projectMenuTab_boq_specification_uom:'uom_id',
    projectMenuTab_boq_Listing_create:'Create',
    projectMenuTab_boq_Listing_view:'View',
    projectMenuTab_boq_Listing_edit:'Edit',
    projectMenuTab_boq_Listing_delete:'Delete',
    projectMenuTab_boq_Listing_boq_ref_no:'BoQ Ref No',
    projectMenuTab_boq_Listing_last_updated_by:'Last Updated By',
    projectMenuTab_boq_Listing_last_updated_at:'Last Updated At',
    projectMenuTab_boq_Listing_status:'Status',
    projectMenuTab_boq_Listing_action:'Action',
    projectMenuTab_boq_management_edit:'Edit',
    projectMenuTab_boq_management_view:'View',
    projectMenuTab_boq_management_client_view:'Client View',
    projectMenuTab_boq_management_aproval:'Approval',
    projectMenuTab_boq_management_send_boq_to_user:'Send BoQ to User',
    projectMenuTab_boq_management_view_history:'View History',
    projectMenuTab_boq_management_delete:'Delete',
    projectMenuTab_boq_management_release_edit_lock:'Release Edit Lock',
    projectMenuTab_boq_management_create_versions:'Create Versions',
    projectMenuTab_boq_management_uploads:'Uploads',
    projectMenuTab_boq_management_downloads:'Downloads',
    projectMenuTab_boq_management_create:'Create',
    projectMenuTab_boq_management_add_masters:'Add Masters',
    projectMenuTab_boq_management_disciplineArea:'Discipline - Area',
    projectMenuTab_boq_management_specId:'Spec ID',
    projectMenuTab_boq_management_productCategory:'Product Category',
    projectMenuTab_boq_management_productSubCategory:'Product Sub-Category',
    projectMenuTab_boq_management_itemName:'Item Name',
    projectMenuTab_boq_management_finishCategory:'Finish Category',
    projectMenuTab_boq_management_finishCategoryType:'Finish Category Type',
    projectMenuTab_boq_management_uom:'UOM',
    projectMenuTab_boq_management_multiplier:'Multiplier',
    projectMenuTab_boq_management_total_item_quantity:'Total Item Quantity',
    projectMenuTab_boq_management_area_quantity:'Area Quantity',
    projectMenuTab_boq_management_total_finish_qantity:'Total Finish Quantity',
    projectMenuTab_boq_management_hsCode:'HS Code',
    projectMenuTab_boq_management_status:'Status',
    projectMenuTab_boq_management_action:'Action',
    projectMenuTab_boq_management_stage:'Stage',

    projectMenuTab_budget:'Budget Management',

    projectMenuTab_budget_summary:'Summary Budget',
    projectMenuTab_budget_detailed:'Detailed Budget',
    projectMenuTab_budget_open_items:'Open Items',

    projectMenuTab_budget_open_items_view:'View',
    projectMenuTab_budget_open_items_discipline_area:'Discipline-Area',
    projectMenuTab_budget_open_items_specID:'Spec ID',
    projectMenuTab_budget_open_items_product_category:'Product Category',
    projectMenuTab_budget_open_items_product_sub_category:'Product Sub-Category',
    projectMenuTab_budget_open_items_item_name:'Item Name',
    projectMenuTab_budget_open_items_uom:'UOM',
    projectMenuTab_budget_open_items_total_qty:'Total Qty',
    projectMenuTab_budget_open_items_stage:'Stage',
    projectMenuTab_budget_open_items_action:'Action',

    projectMenuTab_budget_detailed_view:'View',
    projectMenuTab_budget_detailed_comment:'Comment',
    projectMenuTab_budget_detailed_approve:'Approve',
    projectMenuTab_budget_detailed_download:'Download',
    projectMenuTab_budget_detailed_discipline_area:'Discipline-Area',
    projectMenuTab_budget_detailed_specId:'Spec ID',
    projectMenuTab_budget_detailed_Categpry_subCategory:'Category/Sub-Category',
    projectMenuTab_budget_detailed_vendor:'Vendor',
    projectMenuTab_budget_detailed_item_name:'Item Name',
    projectMenuTab_budget_detailed_uom:'UOM',
    projectMenuTab_budget_detailed_totalItemQty:'Total Item Qty',
    projectMenuTab_budget_detailed_markup:'Markup',
    projectMenuTab_budget_detailed_exworks:'Ex Works',
    projectMenuTab_budget_detailed_cif:'CIF',
    projectMenuTab_budget_detailed_CnD:'C&D',
    projectMenuTab_budget_detailed_duties:'Duties',
    projectMenuTab_budget_detailed_warehouse:'Warehouse',
    projectMenuTab_budget_detailed_installation:'Installation/DDP',
    projectMenuTab_budget_detailed_total:'Total',
    projectMenuTab_budget_detailed_hscode:'HS Code',
    projectMenuTab_budget_detailed_finish_category:'Finish Category',
    projectMenuTab_budget_detailed_finish_type:'Finish Type',
    projectMenuTab_budget_detailed_total_finish_qty:'Total Finish Qty',
    projectMenuTab_budget_detailed_unit_rate:'Unit Rate',

    projectMenuTab_budget_summary_view:'',
    projectMenuTab_budget_summary_download:'',
    projectMenuTab_budget_summary_sendToClient:'',
    projectMenuTab_budget_summary_rfp_Name:'',
    projectMenuTab_budget_summary_package:'',
    projectMenuTab_budget_summary_total:'',
    projectMenuTab_budget_summary_vendor_bid:'',
    projectMenuTab_budget_summary_markup:'',
    projectMenuTab_budget_summary_cif:'',
    projectMenuTab_budget_summary_clearance:'',
    projectMenuTab_budget_summary_duties:'',
    projectMenuTab_budget_summary_warehouse:'',
    projectMenuTab_budget_summary_installation:'',
    projectMenuTab_budget_summary_other:'',
    projectMenuTab_budget_summary_profit_margin:'',
    projectMenuTab_budget_summary_approved:'',

    // projectMenuTab_rfp:'RFP',

    projectMenuTab_rfp_detailCosting:'Detail Costing',

    projectMenuTab_rfp_detailCosting_view:'View',
    projectMenuTab_rfp_detailCosting_save:'Save',
    projectMenuTab_rfp_detailCosting_comment:'Comment',
    projectMenuTab_rfp_detailCosting_download:'Download',
    projectMenuTab_rfp_detailCosting_upload:'Upload',
    projectMenuTab_rfp_detailCosting_disciplineArea:'Discipline-Area',
    projectMenuTab_rfp_detailCosting_SpecId:'Spec ID',
    projectMenuTab_rfp_detailCosting_productCategory:'Product Category',
    projectMenuTab_rfp_detailCosting_productSubCategory:'Product Sub Category',
    projectMenuTab_rfp_detailCosting_itemName:'Item Name',
    projectMenuTab_rfp_detailCosting_totalQty:'Total Qty',
    projectMenuTab_rfp_detailCosting_uom:'UoM',
    projectMenuTab_rfp_detailCosting_hscode:'HS Code',
    projectMenuTab_rfp_detailCosting_unitPrice:'Unit Price',
    projectMenuTab_rfp_detailCosting_totalPrice:'Total Price',
    projectMenuTab_rfp_detailCosting_action:'Action',
    projectMenuTab_rfp_detailCosting_finishCategory:'Finish Category',
    projectMenuTab_rfp_detailCosting_finishType:'Finish Type',
    projectMenuTab_rfp_detailCosting_unitQty:'Unit Qty',
    projectMenuTab_rfp_detailCosting_finishQty:'Finish Qty',
    projectMenuTab_rfp_detailCosting_vendorSpec:'Vendor Spec',

    //ANALYSIS MANAGEMENT
    // projectMenuTab_analysis:'Analysis',
    projectMenuTab_summaryvc:'Summary VC',
    projectMenuTab_detailvc:'Detail VC',
    projectMenuTab_detailvc_spec_id:'Spec ID',
    projectMenuTab_detailvc_name:'Name',
    projectMenuTab_detailvc_qty:'Qty',
    projectMenuTab_detailvc_selected:'Selected',
    projectMenuTab_detailvc_unit_price:'Unit Price',
    projectMenuTab_detailvc_unit_price_base:'Unit Price Base',
    projectMenuTab_detailvc_total_price:'Total Price',
    projectMenuTab_detailvc_total_price_base:'Total Price Base',
    projectMenuTab_detailvc_vendor_spec:'Vendor Spec',
    projectMenuTab_detailvc_selected_unit_price:'Selected Unit Price',
    projectMenuTab_detailvc_selected_total_price:'Selected Total Price',
    projectMenuTab_detailvc_savings:'Savings',
    projectMenuTab_detailvc_percentage:'Percentage',
    projectMenuTab_detailvc_hs_code:'HS Code',

    vendor_enquiries_tab:"Enquiries",
    vendor_requests_tab:"Vendor Request",
    vendor_enquiries_approval:"Approval",
    vendor_enquiries_download:"Download",
    vendor_enquiries_edit:"Edit",
    vendor_enquiries_sendEmail:"Send Email",
    vendor_enquiries_view:"View",
    vendor_enquiries_companyname:"Company Name",
    vendor_enquiries_businessType:"Business Type",
    vendor_enquiries_country:"Country",
    vendor_enquiries_projectRequest:"Project Request",
    vendor_enquiries_emailId:"Email ID",
    vendor_enquiries_datetimeRecieved:"Date/Time Received",
    vendor_enquiries_action:"Action",
    DocumentRegister: "Document Register",
    //catalog tab
    catalogTab_product_name: "Item Name",
    catalogTab_specification: "Specification",
    catalogTab_description: "Description",
    catalogTab_distributor_product: "Distributor Product #",
    catalogTab_manf_product: "Manufacturer Product #",
    catalogTab_brand: "Brand",
    catalogTab_model: "Patter/Model",
    catalogTab_country: "Country of Manufacturing",
    catalogTab_hs_code: "HS Code",
    catalogTab_uom_id: "UOM",
    catalogTab_unit_price: "Unit Price",
    catalogTab_ships_from: "Ships From",
  };
  export default PermissionList;
