import React from "react";
import "antd/dist/antd.css";
import "@grapecity/wijmo.styles/wijmo.css";
import "./Assets/flexGridCell.css";
import "./Assets/default.css";
import "./Assets/project.css";
import "./Assets/boq.css";
import "./Assets/boqPopup.css";
import "./Assets/pqf.css";
import "./Assets/pqq.css";
import "./Assets/login.css";
import "./Assets/client.css";
import "./Assets/master.css";
import "./Assets/vendor.css";
import "./Assets/user.css";
import "./Assets/dashboard.css";
import "./Assets/sidebarCurve.css";
import 'react-phone-input-2/lib/style.css'
import { Route, Switch } from "react-router-dom";
import RouteWithLayout from "./Views/Routes/RoutesHandler";
const Home= React.lazy(() =>  import ("./Views/Common/Home"));
const EnquiryListing= React.lazy(() => import  ("./Views/Common/EnquiryListing"));
const CreateVendorModal= React.lazy(() => import  ("./Views/Common/CreateVendorModal"));
const VendorPQQ= React.lazy(() => import  ("./Views/Vendor/VendorPQQ"));
const BOQ= React.lazy(() => import  ("./Views/BOQ/BOQ"));
// import Budget from "./Views/Budget/Budget";
// import Projects from "./Views/Projects/Projects";
const Users= React.lazy(() => import ("./Views/Users/Users"));
const CreateUser= React.lazy(() => import ("./Views/Users/CreateUser"));
const ViewUser= React.lazy(() => import ("./Views/Users/ViewUser"));
const UserRoles= React.lazy(() => import ("./Views/Users/UserRoles"));
const CreateClient= React.lazy(() => import ("./Views/Client/CreateClient"));
const Client= React.lazy(() => import ("./Views/Client/Client"));
const Discipline= React.lazy(() => import ("./Views/Master/Discipline"));
const Area= React.lazy(() => import ("./Views/Master/Area"));
const TenderCategory= React.lazy(() =>  import ("./Views/Master/TenderCategory"));
const ProductSubCategory= React.lazy(() => import ("./Views/Master/ProductSubCategory"));
const FinishType= React.lazy(() => import ("./Views/Master/FinishType"));
const FinishTypeCategory= React.lazy(() => import ("./Views/Master/FinishTypeCategory"));
const VendorListing= React.lazy(() => import ("./Views/Vendor/VendorListing"));
const TaxTypes= React.lazy(() => import ("./Views/Master/TaxTypes"));
const ChangePassword= React.lazy(() => import ("./Views/Common/Login/ChangePassword"));
const VendorScoring= React.lazy(() => import ("./Views/Vendor/VendorScoring"));
const ProjectCategoryMarkup= React.lazy(() => import ("./Views/Projects/ProjectCategoryMarkup"));
const ProjectSpecification= React.lazy(() => import ("./Views/Projects/ProjectSpecification"));
const ProjectTaxes= React.lazy(() => import ("./Views/Projects/ProjectTaxes"));
const VendorDashboard= React.lazy(() => import ("./Views/Vendor/VendorDashboard"));
const DashboardLayout= React.lazy(() => import ("./Views/Common/Dashboard"));
const DocumentType= React.lazy(() => import ("./Views/Master/DocumentType"));
const Currency= React.lazy(() => import ("./Views/Master/Currency"));
const Provision= React.lazy(() => import ("./Views/Common/Provision"));
const Divisions= React.lazy(() => import ("./Views/Master/Divisions"));
const CreateDivision= React.lazy(() => import ("./Views/Master/CreateDivision"));
const PermissionList= React.lazy(() => import ("./Service/PermissionList"));
const ForgotPassword= React.lazy(() => import ("./Views/Common/Login/ForgotPassword"));
const VerifiedUsers= React.lazy(() => import ("./Views/Users/VerifiedUsers"));
const FillPQF= React.lazy(() => import ("./Views/PQF/FillPQF"));
const ClientDetails= React.lazy(() => import ("./Views/Client/ClientDetails"));
const PQQTemplate= React.lazy(() => import ("./Views/Templates/PQQTemplate"));
const ProjectMasters= React.lazy(() => import ("./Views/ProjectMasters/ProjectMasters"));
const InputMatrixManagement= React.lazy(() => import ("./Views/PQF/InputMatrix/InputMatrixManagement"));
const RoomMatrixDocUpload= React.lazy(() => import ("./Views/PQF/InputMatrix/RoomMatrixDocUpload"));
const FnBMatrixDocUpload= React.lazy(() => import ("./Views/PQF/InputMatrix/FnBMatrixDocUpload"));
const BedMatrixDocUpload= React.lazy(() => import ("./Views/PQF/InputMatrix/BedMatrixDocUpload"));
const Operators= React.lazy(() => import ("./Views/Client/Operators"));
const Designers= React.lazy(() => import ("./Views/Client/Designers"));
const OperatorDesignerDetails= React.lazy(() => import ("./Views/Client/OperatorDesignerDetails"));
const ProjectSpecificationComparison= React.lazy(() => import ("./Views/Projects/ProjectSpecificationComparison"));
const MasterData= React.lazy(() => import ("./Views/Master/MasterData"));
const VendorData= React.lazy(() => import ("./Views/Vendor/VendorData"));
const UserManagement= React.lazy(() => import ("./Views/Users/UserManagement"));
const OperationsData= React.lazy(() => import ("./Views/HeaderMenu/OperationsData"));
const ProjectsMenu= React.lazy(() => import ("./Views/ProjectsCurve/ProjectsMenu"));
const NotificationBoard= React.lazy(() => import ("./Views/Common/NotificationBoard"));
const Author= React.lazy(() => import ("./Views/PQF/PQFForms/Author"));
const PqfOperator= React.lazy(() => import ("./Views/PQF/PQFForms/PqfOperator"));
const Architect= React.lazy(() => import ("./Views/PQF/PQFForms/Architect"));
const PqfDesigner= React.lazy(() => import ("./Views/PQF/PQFForms/PqfDesigner"));
const PqfOther= React.lazy(() => import ("./Views/PQF/PQFForms/PqfOther"));
const BoQManagementView= React.lazy(() => import ("./Views/BOQ/BoQManagementView"));
const VendorEnquiry= React.lazy(() => import ("./Views/Common/VendorEnquiryHome"));
const ProjectContent= React.lazy(() => import ("./Views/ProjectsCurve/ProjectContent"));
const FFNEQuestionnaire2= React.lazy(() => import ("./Views/PQF/PQFForms/FFNEQuestionnaire2"));
const BOQDetails= React.lazy(() => import ("./Views/BOQ/BOQDetails"));
const VendorDashboardManagement= React.lazy(() => import ("./Views/Vendor/VendorDashboardManagement"));
const MenuPqfOSNE= React.lazy(() => import ("./Views/PQF/PQFForms/MenuPqfOSNE"));
const CategoryMarkup= React.lazy(() => import ("./Views/Master/CategoryMarkup"));
const ProjectDashboard= React.lazy(() => import ("./Views/ProjectsCurve/ProjectDashboard"));
const RFPtabs= React.lazy(() => import ("./Views/RFP/RFPtabs"));
const AnalysisManagement= React.lazy(() => import ("./Views/Analysis/AnalysisManagement"));
const BudgetTabs= React.lazy(() => import ("./Views/Budget/BudgetTabs"));
const Enquiry = React.lazy(() => import ("./Views/Common/Enquiry"));
const LoginPage = React.lazy(() => import ("./Views/Common/Login/LoginPage"));

//Class that Defines all the Routes
// Note : All Component Paths need to be added to this class to navigate to the respective component
// class Routes extends React.Component {
const Routes = ({ ...rest }) => {
  return (
    <div>
      <Switch>
        {/* <Route exact path='/' render={(props) => <LoginPage {...this.props} />} /> */}
        {/* #region authentication */}
        <Route
          exact
          path="/login"
          component={LoginPage}
        />
        {/* render={(props) => { return AuthService.authenticated ? <Redirect to="/" /> : <LoginPage {...props} title={"login"} /> }} */}

        <Route
          exact
          path="/forgotPassword"
          component={ForgotPassword}
        />
        <Route
          exact
          path="/Enquiry"
          component={Enquiry}
        />
        <Route
          exact
          path="/VendorEnquiry/:id"
          component={VendorEnquiry}
        />
        {/* For the vendor enqiry link */}
        {/* <Route	     
          exact	         
          path={"/Enquiry/:id"}
          component={Enquiry}
        /> */}
        <Route
          exact
          path="/Vendor"
          component={CreateVendorModal}
        />
        <RouteWithLayout
          exact
          path="/changePassword"
          layout={DashboardLayout}
          component={ChangePassword}
        />
        {/* #end region authentication */}
        <Route exact path="/provision" component={Provision} />
        <RouteWithLayout
          exact
          path="/"
          layout={DashboardLayout}
          component={Home}
        />
        {/* <Route exact path={'/home'} component={Home} /> */}
        <RouteWithLayout
          exact
          path={"/users"}
          layout={DashboardLayout}
          component={Users}
          permission={PermissionList.user}
        />
        <RouteWithLayout
          exact
          path={"/vendors"}
          layout={DashboardLayout}
          component={VendorScoring}
          permission={PermissionList.vendor}
        />
        <RouteWithLayout
          exact
          path={"/clients"}
          layout={DashboardLayout}
          component={Client}
          permission={PermissionList.client}
        />
        {/* TODO: remove when done */}
        <RouteWithLayout
          exact
          path={"/Specification"}
          layout={DashboardLayout}
          component={ProjectSpecification}
          permission={PermissionList.client}
        />
        <RouteWithLayout
          exact
          path={"/clients/clientDetails"}
          layout={DashboardLayout}
          component={ClientDetails}
          permission={PermissionList.client}
        />
        <RouteWithLayout
          exact
          path={"/clients/clientDetails/pqfListing/fillPQF"}
          layout={DashboardLayout}
          component={FillPQF}
          //permission={PermissionList.client}
        />
        <RouteWithLayout
          exact
          path={"/ProjectMasters/ProjectMasters"}
          layout={DashboardLayout}
          component={ProjectMasters}
          // permission={PermissionList.client}
        />
        {/* <RouteWithLayout
          exact
          path={"/projects"}
          layout={DashboardLayout}
          component={DocumentType}
          permission={PermissionList.project}
        /> */}
        <RouteWithLayout
          exact
          path={"/projects/projectTaxes"}
          layout={DashboardLayout}
          component={ProjectTaxes}
        />
        <RouteWithLayout
          exact
          path={"/projects/projectCategoryMarkup"}
          layout={DashboardLayout}
          component={ProjectCategoryMarkup}
        />
        {/* boq mangement view */}
        <RouteWithLayout
          exact
          path={"/BOQManagementView"}
          layout={DashboardLayout}
          component={BoQManagementView}
        />
        {/* <RouteWithLayout
          exact
          path={"/budgets"}
          layout={DashboardLayout}
          component={Budget}
        /> */}
        {/* <RouteWithLayout
          exact
          path={"/budgets/BudgetInstanceEdit"}
          layout={DashboardLayout}
          component={EditBudgetInstance}
        /> */}
        <RouteWithLayout
          exact
          path={"/users/userRoles"}
          layout={DashboardLayout}
          component={UserRoles}
          permission={PermissionList.user}
        />
        <RouteWithLayout
          exact
          path={"/users"}
          layout={DashboardLayout}
          component={CreateUser}
          permission={PermissionList.user}
          isSubPermission={true}
          subPermission={PermissionList.user_create}
        />
        <RouteWithLayout
          exact
          path={"/users/ViewUser"}
          layout={DashboardLayout}
          component={ViewUser}
          permission={PermissionList.user}
          isSubPermission={true}
          subPermission={PermissionList.user_view}
        />
        <RouteWithLayout
          exact
          path={"/users/verifiedUsers"}
          layout={DashboardLayout}
          component={VerifiedUsers}
          permission={PermissionList.user}
          isSubPermission={true}
          subPermission={PermissionList.user_view}
        />
        //#region vendor
        <RouteWithLayout
          exact
          path={"/vendor/vendorScoring"}
          layout={DashboardLayout}
          component={VendorScoring}
        />
        //#endregion //#region Client
        <RouteWithLayout
          exact
          path={"/client/CreateClient"}
          layout={DashboardLayout}
          component={CreateClient}
        //permission={PermissionList.client}
        //isSubPermission={true}
        //subPermission={PermissionList.client_create}
        />
        <RouteWithLayout
          exact
          path={"/client/OperatorDesignerDetails"}
          layout={DashboardLayout}
          component={OperatorDesignerDetails}
          permission={PermissionList.no_permission}
        />
        {/* <RouteWithLayout
          exact
          path={"/client/Operators"}
          layout={DashboardLayout}
          component={Operators}
          permission={PermissionList.no_permission}
        /> */}
        <RouteWithLayout
          exact
          path={"/vendor/vendorlisting"}
          layout={DashboardLayout}
          component={VendorListing}
        />
        <RouteWithLayout
          exact
          path={"/Client/Operators"}
          layout={DashboardLayout}
          component={Operators}
        />
        <RouteWithLayout
          exact
          path={"/Client/Designers"}
          layout={DashboardLayout}
          component={Designers}
        />
        <RouteWithLayout
          exact
          path={"/Projects/ProjectSpecificationComparison"}
          layout={DashboardLayout}
          component={ProjectSpecificationComparison}
        />
        //#endregion Client //#region Templates
        <RouteWithLayout
          exact
          path={"/template/pqq"}
          layout={DashboardLayout}
          component={PQQTemplate}
        />
        <RouteWithLayout
          exact
          path={"/project/pqq"}
          layout={DashboardLayout}
          component={PQQTemplate}
        />
        //#endregion //#region master
        {/* <RouteWithLayout
          exact
          path={"/master/userRoles"}
          layout={DashboardLayout}
          component={UserRoles}
          permission={PermissionList.master}
        /> */}
        <RouteWithLayout
          exact
          path={"/master/discipline"}
          layout={DashboardLayout}
          component={Discipline}
          permission={PermissionList.master}
        />
        <RouteWithLayout
          exact
          path={"/master/area"}
          layout={DashboardLayout}
          component={Area}
          permission={PermissionList.master}
        />
        <RouteWithLayout
          exact
          path={"/master/tenderCategory"}
          layout={DashboardLayout}
          component={TenderCategory}
          permission={PermissionList.master}
        />
        <RouteWithLayout
          exact
          path={"/master/subTender"}
          layout={DashboardLayout}
          component={ProductSubCategory}
          permission={PermissionList.master}
        />
        <RouteWithLayout
          exact
          path={"/master/finishCategory"}
          layout={DashboardLayout}
          component={FinishTypeCategory}
          permission={PermissionList.master}
        />
        <RouteWithLayout
          exact
          path={"/master/finishType"}
          layout={DashboardLayout}
          component={FinishType}
          permission={PermissionList.master}
        />
        <RouteWithLayout
          exact
          path={"/master/documentType"}
          layout={DashboardLayout}
          component={DocumentType}
          permission={PermissionList.master}
        />
        <RouteWithLayout
          exact
          path={"/master/currency"}
          layout={DashboardLayout}
          component={Currency}
          permission={PermissionList.master}
        />
        <RouteWithLayout
          exact
          path={"/master/taxType"}
          layout={DashboardLayout}
          component={TaxTypes}
        />
        <RouteWithLayout
          exact
          path={"/master/divisions"}
          layout={DashboardLayout}
          component={Divisions}
        />
        <RouteWithLayout
          exact
          path={"/master/divisions/createDivision"}
          layout={DashboardLayout}
          component={CreateDivision}
        />
        <RouteWithLayout
          exact
          path={"/MasterData/Discipline"}
          layout={DashboardLayout}
          component={MasterData}
        />
        <RouteWithLayout
          exact
          path={"/MasterData/Area"}
          layout={DashboardLayout}
          component={MasterData}
        />
        <RouteWithLayout
          exact
          path={"/MasterData/ProductCategory"}
          layout={DashboardLayout}
          component={MasterData}
        />
        <RouteWithLayout
          exact
          path={"/MasterData/ProductSubCategory"}
          layout={DashboardLayout}
          component={MasterData}
        />
        <RouteWithLayout
          exact
          path={"/MasterData/FinishCategory"}
          layout={DashboardLayout}
          component={MasterData}
        />
        <RouteWithLayout
          exact
          path={"/MasterData/FinishCategoryType"}
          layout={DashboardLayout}
          component={MasterData}
        />
        <RouteWithLayout
          exact
          path={"/MasterData/Currency"}
          layout={DashboardLayout}
          component={MasterData}
        />
        <RouteWithLayout
          exact
          path={"/MasterData/DocumentType"}
          layout={DashboardLayout}
          component={MasterData}
        />
        <RouteWithLayout
          exact
          path={"/MasterData/TaxType"}
          layout={DashboardLayout}
          component={MasterData}
        />
        <RouteWithLayout
          exact
          path={"/MasterData/VendorTemplates"}
          layout={DashboardLayout}
          component={MasterData}
        />
        <RouteWithLayout
          exact
          path={"/MasterData/Budget"}
          layout={DashboardLayout}
          component={MasterData}
        />
        <RouteWithLayout
          exact
          path={"/MasterData/CategoryMarkup"}
          layout={DashboardLayout}
          component={CategoryMarkup}
        />
        <RouteWithLayout
          exact
          path={"/MasterData/FormulaBuilder"}
          layout={DashboardLayout}
          component={MasterData}
        />
        <RouteWithLayout
          exact
          path={"/MasterData/ProductMaster"}
          layout={DashboardLayout}
          component={MasterData}
        />
        //#endregion master

        //#startregion vendor
        <RouteWithLayout
          exact
          path={"/VendorDashboard"}
          layout={DashboardLayout}
          component={VendorDashboard}
        />
        <RouteWithLayout
          exact
          path={"/VendorData/VendorEnquiryListing"}
          layout={DashboardLayout}
          component={VendorData}
        />
        <RouteWithLayout
          exact
          path={"/VendorData/VendorAllowanceAgreements"}
          layout={DashboardLayout}
          component={VendorData}
        />
        <RouteWithLayout
          exact
          path={"/VendorData/VendorManagement/osne"}
          layout={DashboardLayout}
          component={VendorData}
        />
        <RouteWithLayout
          exact
          path={"/VendorData/VendorManagement/ffne"}
          layout={DashboardLayout}
          component={VendorData}
        />
        //#endregion vendor

        {/* <RouteWithLayout
          exact
          path={"/projects"}
          layout={DashboardLayout}
          component={Projects}
          permission={PermissionList.master}
        /> */}
        <RouteWithLayout
          exact
          path={"/PQF/InputMatrix/InputMatrix"}
          layout={DashboardLayout}
          component={InputMatrixManagement}
        />
        <RouteWithLayout
          exact
          path={"/PQF/InputMatrix/RoomMatrixDocUpload"}
          layout={DashboardLayout}
          component={RoomMatrixDocUpload}
        />
        <RouteWithLayout
          exact
          path={"/PQF/InputMatrix/FnBMatrixDocUpload"}
          layout={DashboardLayout}
          component={FnBMatrixDocUpload}
        />
        <RouteWithLayout
          exact
          path={"/PQF/InputMatrix/BedMatrixDocUpload"}
          layout={DashboardLayout}
          component={BedMatrixDocUpload}
        />
        <RouteWithLayout
          exact
          // path={"/BOQ/:id/:client_name/:project_name"}
          path={"/BOQ"}
          layout={DashboardLayout}
          component={BOQ}
        />
        <RouteWithLayout
          exact
          path={"/UserManagement"}
          layout={DashboardLayout}
          component={UserManagement}
        />
        <RouteWithLayout
          exact
          path={"/OperationsData/Enquiries"}
          layout={DashboardLayout}
          component={OperationsData}
        />
        <RouteWithLayout
          exact
          path={"/OperationsData/Users"}
          layout={DashboardLayout}
          component={OperationsData}
        />
        <RouteWithLayout
          exact
          path={"/OperationsData/Clients"}
          layout={DashboardLayout}
          component={OperationsData}
        />
        <RouteWithLayout
          exact
          path={"/OperationsData/Projects"}
          layout={DashboardLayout}
          component={OperationsData}
        />
        <RouteWithLayout
          exact
          path={"/OperationsData/Operators"}
          layout={DashboardLayout}
          component={OperationsData}
        />
        <RouteWithLayout
          exact
          path={"/OperationsData/Designers"}
          layout={DashboardLayout}
          component={OperationsData}
        />
        <RouteWithLayout
          exact
          path={"/OperationsData/Vendors"}
          layout={DashboardLayout}
          component={OperationsData}
        />
        <RouteWithLayout
          exact
          path={"/OperationsData/Divisions"}
          layout={DashboardLayout}
          component={OperationsData}
        />
        <RouteWithLayout
          exact
          path={"/projectsCurve"}
          layout={DashboardLayout}
          component={ProjectsMenu}
        />
        {/* ENQUIRIES */}
        <RouteWithLayout
          exact
          path={"/EnquiryListing"}
          layout={DashboardLayout}
          component={EnquiryListing}
        />
        {/* VENDORS */}
        <RouteWithLayout
          exact
          path={"/VendorPQQ"}
          layout={DashboardLayout}
          component={VendorPQQ}
        />
        <RouteWithLayout
          exact
          path={"/NotificationBoard"}
          layout={DashboardLayout}
          component={NotificationBoard}
        />
        <RouteWithLayout
          exact
          path={"/Author"}
          layout={DashboardLayout}
          component={Author}
        />
        <RouteWithLayout
          exact
          path={"/PqfOperator"}
          layout={DashboardLayout}
          component={PqfOperator}
        />
        <RouteWithLayout
          exact
          path={"/Architect"}
          layout={DashboardLayout}
          component={Architect}
        />
        <RouteWithLayout
          exact
          path={"/PqfDesigner"}
          layout={DashboardLayout}
          component={PqfDesigner}
        />
        <RouteWithLayout
          exact
          path={"/PqfOther"}
          layout={DashboardLayout}
          component={PqfOther}
        />
        {/* Project Dashboard */}
        <RouteWithLayout
          exact
          path={"/ProjectDashboard"}
          layout={DashboardLayout}
          component={ProjectDashboard}
        />
        {/* Project info */}
        <RouteWithLayout
          path={"/ProjectInformation/:id"}
          layout={DashboardLayout}
          component={ProjectContent}
        />
        <RouteWithLayout
          exact
          path={"/FF&E-PQF"}
          layout={DashboardLayout}
          component={FFNEQuestionnaire2}
        />
        <RouteWithLayout
          exact
          path={"/BOQDetails"}
          layout={DashboardLayout}
          component={BOQDetails}
        />
        <RouteWithLayout
          exact
          path={"/VendorDashboardManagement"}
          layout={DashboardLayout}
          component={VendorDashboardManagement}
        />
        <RouteWithLayout
          exact
          path={"/BudgetDashboardManagement"}
          layout={DashboardLayout}
          component={BudgetTabs}
        />
        <RouteWithLayout
          exact
          path={"/RFPDashboardManagement"}
          layout={DashboardLayout}
          component={RFPtabs}
        />
        <RouteWithLayout
          exact
          path={"/AnalysisDashboardManagement"}
          layout={DashboardLayout}
          component={AnalysisManagement}
        />
        <RouteWithLayout
          exact
          path={"/OS&E-PQF"}
          layout={DashboardLayout}
          component={MenuPqfOSNE}
        />
      </Switch>
    </div>
  );
};

export default Routes;
