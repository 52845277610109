const CookieList = {
    id:'userId',
    user_name:'userName',
    user_first_name: 'userFirstName',
    user_full_name: 'userFullName',
    user_token: 'accessToken',
    user_email: 'userEmail',
    user_permissions:'userPermissions',
    user_role:'role',
    user_role_type:'roleType',
    user_master_data:'userMasterData',
    user_Operations_data:'userOperationsData',
    user_Dashboard_data:'userDashboardData',
    user_mapping_id:'mappingId',
    user_profile_pic:'userProfilePic',
    user_role_id:'userRoleId',
    unsavedValue: 'unsavedValue',
   // user_operation_data_permissions:'userOperationsDataPermissions'
  };
  export default CookieList;
  