// const BaseURL = "http://webapi.local:8080/web-api";
// const BaseURL = "http://172.16.2.52:8080/web-api";
// const BaseURL = "http://172.16.2.52:8082";//local server
// const BaseURL = "http://52.149.165.242:8082"; //UAT server

// const BaseURL = "http://api.profitechnologies.com:8082"; //Prod server

const BaseURL = process.env.REACT_APP_APIURL; //UAT server

// const BaseURL = "http://localhost:51443";

// const BaseURL = window.location.hostname === "localhost"?"https://api.provisionuat.eastus.cloudapp.azure.com"
// :window.location.hostname === "provisionuat.eastus.cloudapp.azure.com"?"http://provisionuat.eastus.cloudapp.azure.com"
// :"https://budgetapi.provisionllc.com";//UAT Server

//const BaseURL = "http://103.97.94.90:8086"; //Local Dev (exposed)
//const BaseURL = "http://52.149.165.242"; //UAT(Dev) server

const WebLinks = {
  //UserManagement
  LoginLink: BaseURL + "/api/Login",
  LogoutLink: BaseURL + "/api/user/logout",
  UsersLink: BaseURL + "/api/User",
  TokenLink: BaseURL + "/Token",
  RoleLink: BaseURL + "/api/Role",
  GetFeaturePermissionsAssignedLink: BaseURL + "/api/GetFeaturePermissionsAssigned",
  ChangePasswordLink: BaseURL + "/api/ChangePassword",
  ForgotPasswordLink: BaseURL + "/api/ForgotPassword",
  InsertPermissionsLink: BaseURL + "/api/InsertPermissions",
  UserCreateResLink: BaseURL + "/api/GetUserCreateRes?id=",

  //Client Management
  ClientLink: BaseURL + "/api/Clients",
  UserClientLink: BaseURL + "/api/user/client",
  ClientProjectsLink: BaseURL + "/api/Projects/GetProjects?clientId=",

  //Vendor Enquiries
  VendorGetEnquiriesLink: BaseURL + "/api/Vendor/GetVendorEnquiryListing",
  VendorGetRequestDetailsEnquiryLink: BaseURL + "/api/Vendor/GetRequestDetails?req_id=",
  VendorGetApprovedProjectVendorLink: BaseURL + "/api/VendorSelectionProject/GetApprovedProjectVendors?proj_id=",
  VendorEnquirySendSaveLink: BaseURL + "/api/Vendor/SaveEnquiryVendorDetails",
  VendorEnquiryDetailsByIdLink: BaseURL + "/api/Vendor/GetVendorEnquiryById?id=",
  VendorsRejectVendorLink: BaseURL + "/api/Vendor/RejectVendor?id=",
  VendorAcceptNSendPQQLink: BaseURL + "/api/VendorPQQ/AcceptVendorSendPQQ",
  VendorEnquiryAddCommentLink: BaseURL + "/api/Vendor/PostVendorEnquiryComment",
  VendorRequestClientApprovedVendorsPutLink: BaseURL + "/api/VendorSelectionProject/UpdateClientApprovedVendors?client_approved=",
  VendorEnquiriesGetCommentsLink: BaseURL + "/api/Vendor/GetVendorEnquiryComment?id=",
  VendorEnquiryNotifyCommentLink: BaseURL + "/api/Vendor/NotifyVendorEnquiryComments?",
  VendorEnquirieGetProjectsLink: BaseURL + "/api/Projects/GetProjectsWithCustomPQQCreated",
  VendorEnquirySaveEditLink: BaseURL + "/api/Vendor/SaveVendorDetails",
  getCategory: BaseURL + "/api/Vendor/GetEnquiryDropDownList?proj_id=",

  //Vendor Management
  VendorUpdateTCLink: BaseURL + "/api/Vendor/UpdateVendorTermsAndConditions",
  VendorCreatePQQLink: BaseURL + "/api/VendorPQQ/CreateVendorPQQ",
  VendorLink: BaseURL + "/api/Vendor",
  VendorScoring:
    BaseURL + "/api/VendorScoringQualityCriteria/GetMasterScoringTemplate",
  UnscoredQuestion:
    BaseURL + "/api/VendorScoringQualityCriteria/GetUnScoredQuestion",
  SubmitVendorScoring:
    BaseURL + "/api/VendorScoringQualityCriteria/ScoringTemplate",
  VendorDetailsGetByIdLink: BaseURL + "/api/Vendor/GetVendorDetails?vendor_id=",
  VendorDetailsGetByIdLink2: BaseURL + "/api/Vendor/GetVendorById?id=",
  VendorDetailsPostLink: BaseURL + "/api/Vendor/CreateVendorDetails",
  VendorRebatePostLink: BaseURL + "/api/Vendor/SaveVendorRebate",
  VendorDetailsPutLink: BaseURL + "/api/Vendor/UpdateVendorDetails",
  VendorSubCatGetLink: BaseURL + "/api/Vendor/GetVendorSubCatList?vendor_id=",
  VendorFileUploadGetLink:
    BaseURL + "/api/Vendor/GetFileUploadsListing?vendor_id=",
  VendorFileUploadPostLink: BaseURL + "/api/Vendor/SaveFileUploads",
  VendorAcceptRejectPutLink: BaseURL + "/api/Vendor/ApproveRejectVendors",
  VendorListGetLink: BaseURL + "/api/Vendor/GetVendorList?unit_type=",
  VendorListForProjectGetLink: BaseURL + "/api/Vendor/GetVendorListForProjects?unit_type=",
  VendorApprovedBOQIdGetLink:
    BaseURL + "/api/BoQ/GetClientApprovedBoQId?proj_id=",
  VendorSelectBOQGetLink:
    BaseURL + "/api/VendorIndicativePricing/GetSelectedVendorsForBoQ?boq_id=",
  BOQVendorPricingPostLink:
    BaseURL + "/api/VendorIndicativePricing/SendBoQForVendorPricing",
  VendorByUnitTypeGetLink: BaseURL + "/api/Vendor/GetVendorsByUnitType?",
  VendorConsolidatedViewGetLink:
    BaseURL +
    "/api/VendorIndicativePricing/GetSpecVendorPriceConsolidated?boq_id=",
  VendorSpecPriceGetLink:
    BaseURL + "/api/VendorIndicativePricing/GetVendorSpecPrices?",
  VendorSpecPricePostLink:
    BaseURL + "/api/VendorIndicativePricing/SaveVendorBoQSpecPrices",
  VendorMasterPQQGetLink:
    BaseURL + "/api/VendorPQQ/GetMasterVendorPQQ?vendor_id=",
  VendorPQQGetLink: BaseURL + "/api/VendorPQQ/api/GetVendorPQQ?vendor_id=",
  VendorPQQGetProjects: BaseURL + "/api/VendorPQQ/api/GetPQQListByVendorId?vendor_id=",
  VendorPQQByIdGetLink: BaseURL + "/api/VendorPQQ/GetVendorPQQById?id=",
  VendorPQQPutLink: BaseURL + "/api/VendorPQQ/UpdateVendorPqq?id=",
  VendorGetBOQListLink:
    BaseURL + "/api/VendorIndicativePricing/GetBoQListForPricing?vendor_id=",
  VendorPQQPutSaveLink: BaseURL + "/api/VendorPQQ/SaveVendorPqq?id=",
  VendorUpdateStatusLink: BaseURL + "/api/Vendor/UpdateVendorStatus",
  VendorPQQGetDocTypesLink: BaseURL + "/api/VendorPQQ/GetVendorPQQDocType",
  VendorSignupLink: BaseURL + "/api/VendorSignUp/VendorDetails",
  VendorSendMailLink: BaseURL + "/api/Vendor/SendVendorEnquiryLink?",
  ClientsProjectsLinkedToVendorLink: BaseURL + "/api/Vendor/ClientsProjectsLinkedToVendor?id=",
  VendorDeleteDocmumentList: BaseURL + "/api/Vendor/DeleteDocuments",
  VendorCommentSave: BaseURL + "/api/VendorSelectionProject/Save",
  VendorCommentGet: BaseURL + "/api/VendorSelectionProject/GetSelectedVendorForProject?proj_id=",
  VendorRequestGetLink: BaseURL + "/api/GetAllProjectVendorRequest",
  ProjectVendorRequestsGetLink: BaseURL + "/api/GetProjectVendorRequests?",
  GetVendorAllowance: BaseURL + "/api/VendorAllowanceAgreement?vendor_id=",
  SaveVendorAllowanceProvision: BaseURL + "/api/VendorAllowanceAgreement",
  SaveVendorAllowanceVendor: BaseURL + "/api/VendorAllowanceAgreement/VendorSave",
  GetAllowanceTemplateVersionList: BaseURL + "/api/GetAllowanceTemplateVersionList",
  GetAllowanceAgreementTemplateLatestVersionDetails: BaseURL + "/api/GetAllowanceAgreementTemplateLatestVersionDetails",
  SaveAllowanceAgreementTemplate: BaseURL + "/api/AllowanceAgreementTemplate",
  VendorSendToClient: BaseURL + "/api/VendorSelectionProject/ApproveSelectedVendors",
  GetClientDataLink: BaseURL + "/api/GetClientUserEmailList?project_id=",
  SendClientsEmail: BaseURL + "/api/VendorSelectionProject/SendPendingApprovalNotificationClient?proj_id=",
  SearchVendorCatalogGetLink: BaseURL + "/api/VendorCatalogItem?search_term=",
  ApproveRejectVendor: BaseURL + "/api/Vendor/ApproveRejectVendor",
  VendorReopen: BaseURL + "/api/Vendor/ReOpenVendor",
  ReOpenDeleteAllowance: BaseURL + "/api/VendorAllowanceAgreement?vendor_id=",

  //Masters
  DisciplineLink: BaseURL + "/api/Discipline",
  DisciplineBulkDeleteLink: BaseURL + "/api/Discipline/BulkDelete",
  ProjectDisciplineBulkDeleteLink: BaseURL + "/api/ProjectDiscipline/BulkDelete",
  BedTypesLink: BaseURL + "/api/BedType",
  RoomTypesLink: BaseURL + "/api/RoomType",
  AreaLink: BaseURL + "/api/Area",
  AreaSaveLink: BaseURL + "/api/Area/Save",
  AreaBulkDeleteLink: BaseURL + "/api/Area/BulkDelete",
  CategoryLink: BaseURL + "/api/Category",
  SubCategoryLink: BaseURL + "/api/SubCategory",
  ProductCategoryBulkDelete: BaseURL + "/api/Category/BulkDelete",
  ProductSubCategoryBulkDelete: BaseURL + "/api/SubCategory/BulkDelete",
  BusinessUnitTypeLink: BaseURL + "/api/BusinessUnitType",
  BusinessUnitTypeNoAuthLink: BaseURL + "/api/GetBusinessUnitType",
  FinishTypeLink: BaseURL + "/api/FinishType",
  FinishTypeSaveLink: BaseURL + "/api/FinishTypes/Save",
  FinishTypeBulkDeleteLink: BaseURL + "/api/FinishTypes/BulkDelete",
  ProjectFinishTypeSaveLink: BaseURL + "/api/ProjectFinishType/Save",
  ProjectFinishTypeBulkDeleteLink:
    BaseURL + "/api/ProjectFinishType/BulkDelete",
  FinishTypeCategoryLink: BaseURL + "/api/FinishTypeCategory",
  FinishTypeCategorySaveLink: BaseURL + "/api/FinishTypeCategory/Save",
  FinishTypeCategoryBulkDeleteLink:
    BaseURL + "/api/FinishTypeCategory/BulkDelete",
  DocumentTypeLink: BaseURL + "/api/DocumentType",
  DocumentTypeBulkDeleteLink: BaseURL + "/api/DocumentType/BulkDelete",
  EditableDocumentTypeLink:
    BaseURL + "/api/DocumentType/GetAllEditableDocuments",
  ReferenceTypeLink: BaseURL + "/api/References?ref_type=",
  CurrencyLink: BaseURL + "/api/Currency",
  CurrencyActiveLink: BaseURL + "/api/Currency/GetActiveCurrency",
  CurrencyBulkDeleteLink: BaseURL + "/api/Currency/BulkDelete",
  TaxTypesLink: BaseURL + "/api/TaxType",
  TaxTypesActiveLink: BaseURL + "/api/TaxTypes/GetActiveTaxTypes",
  TaxTypesBulkDeleteLink: BaseURL + "/api/TaxTypes/BulkDelete",
  DivisionsLink: BaseURL + "/api/Divisions",
  CategoryMarkupLink: BaseURL + "/api/CategoryMarkup",
  CategoryMarkupSaveLink: BaseURL + "/api/CategoryMarkup",

  //Admin
  AdminUserLink: BaseURL + "/api/AdminUserList",

  //UpdateBulkStatus
  UpdateStatusLink: BaseURL + "/api/UpdateStatus",

  //Masters Wijmo APIs
  UpdateDiscipline: BaseURL + "/api/Discipline/Save",
  UpdateDisciplineProject: BaseURL + "/api/ProjectDiscipline/Save",
  UpdateProductCategory: BaseURL + "/api/Category/Save",
  UpdateProductCategoryProject: BaseURL + "/api/ProjectCategory/Save",
  UpdateProductSubCategory: BaseURL + "/api/SubCategory/Save",
  UpdateProductSubCategoryProject:
    BaseURL + "/api/SubCategory/api/ProjectSubCategory/Save",
  UpdateDocumentType: BaseURL + "/api/DocumentType/Save",
  UpdateCurrency: BaseURL + "/api/Currency/Save",
  UpdateTaxType: BaseURL + "/api/TaxTypes/Save",

  //PQF
  PQFScheduleGetLink:BaseURL + "/api/Projects/GetProjectOpeningDate?project_id=",
  PQFPercentageGetLink: BaseURL + "/api/Dashboard/Projects/GetPQFPercentage?pqf_id=",
  PQFFinalSubmitLink: BaseURL + "/api/SubmitPQF?id=",
  PQFListingLink: BaseURL + "/api/UserPQF?client_id=",
  ClientFFNEPQFLink: BaseURL + "/api/GetPQFffeDetails?pqf_id=",
  ClientFFNEPQFLinkNew: BaseURL + "/api/PQF/GetffePQFDrawings?pqf_id=",
  ClientFFNEPQFSpecLinkNew:
    BaseURL + "/api/PQF/GetffePQFSpecifications?pqf_id=",
  ClientOSNEPQFLink: BaseURL + "/api/GetPQFoseDetails?pqf_id=",
  OSNEPQFGetFBLink: BaseURL + "/api/GetPQFOsneFbDetails?pqf_id=",
  OSNEPQFPostFBLink: BaseURL + "/api/SavePQFOsneFb?pqf_id=",
  OSNEPQFDeleteFBLink: BaseURL + "/api/PQFOsneFB/BulkDelete",
  OSNEPQFGetBanquetsLink: BaseURL + "/api/GetPQFOsneBanquetsDetails?pqf_id=",
  OSNEPQFPostBanquetsLink: BaseURL + "/api/SavePQFOsneBanquets?pqf_id=",
  OSNEPQFGetRecreationalAreasLink: BaseURL + "/api/GetPQFOsneRecreationalAreasDetails?pqf_id=",
  OSNEPQFPostRecreationalAreasLink: BaseURL + "/api/SavePQFOsneRecreationalAreas?pqf_id=",
  OSNEPQFGetOthersAreasLink: BaseURL + "/api/GetPQFOsneOthers?pqf_id=",
  OSNEPQFPostOthersAreasLink: BaseURL + "/api/SavePQFOsneOthers?pqf_id=",
  ClientPQFLink: BaseURL + "/api/ClientPQF",
  FFNEPQFLink: BaseURL + "/api/ClientFfePQF?pqf_id=",
  FFNEPQFLinkNew: BaseURL + "/api/PQF/PostffePQFDrawings?pqf_id=",
  FFNEPQFSpecLinkNew: BaseURL + "/api/PQF/PostffePQFSpecifications?pqf_id=",
  OSNEPQFLink: BaseURL + "/api/ClientOsePQF?pqf_id=",
  PQFLink: BaseURL + "/api/UserPQF",
  PQFFileUploadsLink: BaseURL + "/api/GetPQFFileUploads",
  SendPQFFromEnquiry: BaseURL + "/api/SendPQFFromEnquiry",

  //Input Matrix
  InputMatrixGetLink: BaseURL + "/api/GetInputMatrix?",
  BedMatrixPostLink: BaseURL + "/api/PostBedMatrix?pqf_id=",
  FBMatrixPostLink: BaseURL + "/api/PostFBMatrix?pqf_id=",
  RoomMatrixPostLink: BaseURL + "/api/PostRoomMatrix?pqf_id=",
  InuptMatrixSave: BaseURL + "/api/PostPublicArea?pqf_id=",

  //Project Masters
  ProjectMasterDisciplineLink: BaseURL + "/api/ProjectDiscipline?project_id=",
  //ProjectMasterDisciplinelink: BaseURL + "/api/ProjectDiscipline",// TODO: use this link for project discipline and remove the duplicate links
  //ProjectMasterArealink: BaseURL + "/api/ProjectAreas",// TODO: use this link for project Area and remove the duplicate links
  ProjectMasterAreaLink: BaseURL + "/api/ProjectAreas?project_id=",
  ProjectMasterFinishTypeCategoryLink:
    BaseURL + "/api/ProjectFinishTypeCategory?project_id=",
  ProjectMasterFinishTypeLink: BaseURL + "/api/ProjectFinishTypes?project_id=",
  ProjectMasterTenderCategoryLink: BaseURL + "/api/ProjectCategory?project_id=",
  ProjectMasterTenderSubCategoryLink:
    BaseURL + "/api/ProjectSubCategory?proj_id=",

  ProjectMasterDisciplineHandleLink: BaseURL + "/api/ProjectDiscipline",
  ProjectMasteAreaHandleLink: BaseURL + "/api/ProjectAreas",
  ProjectAreaSave: BaseURL + "/api/ProjectAreas/Save",
  ProjectAreaBulkDelete: BaseURL + "/api/ProjectAreas/BulkDelete",
  ProjectProductSubCategoryBulkDelete: BaseURL + "/api/SubCategory/api/SubCategory/BulkDelete",
  ProjectProductCategoryBulkDelete: BaseURL + "/api/ProjectCategory/BulkDelete",
  ProjectMasterFinishTypeHandleLink: BaseURL + "/api/ProjectFinishTypes",
  ProjectMasterFinishTypeSaveLink:
    BaseURL + "/api/ProjectFinishTypeCategory/Save",
  ProjectMasterFinishTypeCategoryBulkDeleteLink:
    BaseURL + "/api/ProjectFinishTypeCategory/BulkDelete",
  ProjectMasterFinishTypeCategoryHandleLink:
    BaseURL + "/api/ProjectFinishTypeCategory",
  ProjectMasterTenderCategoryHandleLink: BaseURL + "/api/ProjectCategory",
  ProjectMasterTenderSubCategoryHandleLink: BaseURL + "/api/ProjectSubCategory",

  ProjectSpecifiedDisciplineLink:
    BaseURL + "/api/ProjectDiscipline?project_id=",
  ProjectSpecifiedAreaLink: BaseURL + "/api/ProjectAreas?project_id=",

  //Project
  ProjectSubCategoryMarkup: BaseURL + "/api/ProjectSubCatMarkup?project_id=",
  ProjectTaxes: BaseURL + "/api/ProjectTaxes?project_id=",
  ProjectTaxesHandleLink: BaseURL + "/api/ProjectTaxes",
  ProjectDetailsPopulateHandleLink:
    BaseURL + "/api/ProjectScreenValues?client_id=",
  ProjectSubCategoryMarkupHandleLink: BaseURL + "/api/ProjectSubCatMarkup",
  ProjectsubCategoryMarkupPopulateHandleLink:
    BaseURL + "/api/ProjectSubCatMarkup/SubCategories?project_id=",
  ProjectDocumentRegisterLink: BaseURL + "/api/Document?",
  ProjectDocumentRegisterPostLink:
    BaseURL + "/api/Projects/AddDocumentRegister?project_id=",
  ProjectHandleLink: BaseURL + "/api/Projects",
  ProjectResourcesHandleLink:
    BaseURL + "/api/Projects/GetResources?project_id=",
  ProjectEnquiryHandleLink: BaseURL + "/api/ProjectEnquiry",
  ProjectDetails: BaseURL + "/api/Dashboard/Projects/Projects",
  ProjectItemDetails:
    BaseURL + "/api/Dashboard/Projects/GetProjectDetails?project_id=",
  ProjectItemHistory: BaseURL + "/api/ProjectSpecification/GetItemHistory",
  ProjectByBusinessUnitIdGetLink: BaseURL + "/api/Dashboard/Projects/ListOfProjectsByBusinessUnit?unit_type=",

  //Document Register
  GetDocumentRegister: BaseURL + "/api/DocumentRegister?proj_id=",
  SubmitDocumentRegister: BaseURL + "/api/DocumentRegister?tab=",
  GetDocumentDateHistory: BaseURL + "/api/GetDocumentRegisterDateHistory?id=",

  // ProjectSpecifications
  ProjectSpecification: BaseURL + "/api/ProjectSpecification",
  ProjectSpecCatigoryMarkup:
    BaseURL + "/api/ProjectSpecification/SpecCategoryMarkup",
  ProjectSpecClassifications:
    BaseURL + "/api/ProjectSpecification/GetAllClassifications",
  ProjectSpecDocument: BaseURL + "/api/ProjectSpecification/SpecDocument",
  ProjectSpecificationCompareVersion:
    BaseURL +
    "/api/ProjectSpecification/GetSpecificationVersionNames?project_id=",
  ProjectSpecificationDataHandleLink:
    BaseURL + "/api/ProjectSpecification/GetSpecificationByVersion?project_id=",
  ProjectSpectItemDeleteCheckLink: BaseURL + "/api/ProjectSpecification/CheckIfSpecItemExistsInLatestBoQ?",

  //Template
  MasterPQQLink: BaseURL + "/api/MasterPQQTemplate",
  MasterPQQSaveLink: BaseURL + "/api/MasterPQQTemplate/SaveMasterPQQ?id=",
  PQFTemplateDocsLink: BaseURL + "/api/PQFTemplateDocs?pqf_id=",
  MasterStarTemplateGetLink: BaseURL + "/api/MasterStarTemplate/GetAllStarTemplates",
  MasterStarTemplateDeleteLink: BaseURL + "/api/MasterStarTemplate/DeleteStarTemplate?id=",
  MasterStarTemplateCreateLink: BaseURL + "/api/MasterStarTemplate/Create?name=",
  //ProjectTemplate
  ProjectPQQGetLink: BaseURL + "/api/MasterPQQTemplate/ProjectPQQ/GetByProject",
  ProjectVendorScoringMatrixGetLink:
    BaseURL +
    "/api/VendorScoringQualityCriteria/GetProjectScoringTemplate?project_id=",
  ProjectVendorScoringQualityCriteriaGetLink:
    BaseURL +
    "/api/VendorScoringQualityCriteria/GetProjectUnScoredQuestion?project_id=",
  ProjectVendorScoringQualityCriteriaPostLink:
    BaseURL +
    "/api/VendorScoringQualityCriteria/ProjectScoringTemplate?project_id=",
  ProjectPQQLink: BaseURL + "/api/MasterPQQTemplate/ProjectPQQ",
  ProjectPQQSaveLink: BaseURL + "/api/MasterPQQTemplate/SaveProjectPQQ",


  //Operators
  OperatorHandleLink: BaseURL + "/api/Operator",
  ProjectsByOperatorIdGetLink: BaseURL + "/api/GetProjects?operator_id=",

  //Designers
  DesignerHandleLink: BaseURL + "/api/Designer",
  ProjectsByDesignerIdGetLink: BaseURL + "/api/GetProjectsbyDesigner?id=",

  //Enquiries
  EnquiriesGetAllLink: BaseURL + "/api/ProjectEnquiry",
  EnquiriesPutUpdateLink: BaseURL + "/api/ProjectEnquiry",
  EnquiriesPutLink: BaseURL + "/api/ProjectEnquiry/UpdateStatus?",
  EnquiriesGetCommentsByIDLink: BaseURL + "/api/ProjectEnquiry/GetEnquiryComments?id=",
  EnquiriesPostCommentsLink: BaseURL + "/api/ProjectEnquiry/PostEnquiryComment",
  EnquiriesPostNotifyEmailCommentsLink: BaseURL + "/api/ProjectEnquiry/NotifyEnquiryComments?",
  EnquiriesGetAllEmailsLink: BaseURL + "/api/ProjectEnquiry/GetProvEmailList",

  //BillToShip
  BillToShipLink: BaseURL + "/api/PQFBillShipAddress?pqf_id=",
  BillToShipPostLink: BaseURL + "/api/PQFBillShipAddress",

  //File
  FileUploadLink: BaseURL + "/api/UploadFile",
  FileDownloadLink: BaseURL + "/api/DownloadFile",
  FileDeleteLink: BaseURL + "/api/DeleteFile",
  SpecFileRemoveLink: BaseURL + "/api/ProjectSpecification/DeleteUploadedSpecFile",
  // pqf delete files
  DBDeleteFile: BaseURL + "/api/PQF/DeleteDocuments?",//pqf_id={pqf_id}&page_type={page_type}

  //BOQ
  GetBOQDetails: BaseURL + "/api/GetBoQByProjId?proj_id=",
  GetBoQDropDownLists: BaseURL + "/api/GetBoQDropDownLists?proj_id=",
  // GetBoQData: BaseURL + "/api/GetBoQById?boq_id=",
  GetBoQData: BaseURL + "/api/GetFFnEBoQDetailsProv?boq_id=",
  GetClientBoQData: BaseURL + "/api/GetFFnEBoQDetailsClient?boq_id=",
  BOQ: BaseURL + "/api/BoQ",
  CreateBOQ: BaseURL + "/api/CreateBoQ",
  SaveFFnEBoQDetailsClient: BaseURL + "/api/SaveFFnEBoQDetailsClient",
  SaveFFnEBoQDetailsProv: BaseURL + "/api/SaveFFnEBoQDetailsProv",
  UpdateBoQStatus: BaseURL + "/api/UpdateBoQApprovalStatus",
  GetFFnEBoqItemHistory: BaseURL + "/api/GetFFnEBoqItemHistory?id=",
  SendBoQForApproval: BaseURL + "/api/SendBoQForApproval",
  SendBoQForClientApproval: BaseURL + "/api/SendBoQForClientApproval",
  GetFilterOnDataFFnEBoQ: BaseURL + "/api/FilterOnDataFFnEBoQ?boq_id=",
  FilterFFnEBoQItems: BaseURL + "/api/FilterFFnEBoQItems",
  CreateBoQNewVersion: BaseURL + "/api/CreateBoQNewVersion",
  FilterFFnEBoQItemsClient: BaseURL + "/api/FilterFFnEBoQItemsClient",
  GetLatestFFnEBOQProv: BaseURL + "/api/GetLatestVersionFFnEBoQDetailsProv",
  GetLatestFFnEBOQClient: BaseURL + "/api/GetLatestVersionFFnEBoQDetailsClient",
  GetUserAssignedToProject: BaseURL + "/api/Projects/GetUsersAssignedToProject?project_id=",
  SendAssignUserToEntity: BaseURL + "/api/UserEntityAccess/AssignEntityToUser",
  GetExteralUsers: BaseURL + "/api/ExternalUsers?proj_id=",
  PullBackBoq: BaseURL +  "/api/PullBackBoQ",

  // Edit lock
  LockEntityforEditing: BaseURL + "/api/UserEntityAccess/LockEntityforEditing",
  ReleaseEditLockofEntity: BaseURL + "/api/UserEntityAccess/ReleaseEditLockofEntity",
  LockEntity: BaseURL + "/api/UserEntity/LockEdit",
  ReleaseLock: BaseURL + "/api/UserEntity/ReleaseEditLock",

  //Permission
  PermissionHandleLink: BaseURL + "/api/GetPermissions?tab_id=",
  PermissionFeatureLink: BaseURL + "/api/GetTabsPermissions?feature_id=",

  //Notifications
  NotificationsGetLink: BaseURL + "/api/UserNotifications",
  NotificationsVendorGetLink: BaseURL + "/api/UserNotificationsVendor",
  NotificationsDeleteLink: BaseURL + "/api/UserNotifications/",
  NotificationsLink: BaseURL + "/api/UserNotificationsUpdated/",

  //Master refrences
  MasterRefrenceLink: BaseURL + "/api/MasterReferenceStatus?",
  MasterBulkRefrenceLink: BaseURL + "/api/GetBulkReferences",

  //Dashboard
  DashboardLink: BaseURL + "/api/Dashboard/Projects/Projects",
  ProjectDasboardDetailsLink:
    BaseURL + "/api/Dashboard/Projects/GetProjectDetails?project_id=",

  //User PQF list
  UserSpecifiedPQFListLink: BaseURL + "/api/GetUserSpecificPQFList",

  //Formula Builder
  FormulaBuilderLink: BaseURL + "/api/FormulaBuilder",
  GetFormulaKeysLink: BaseURL + "/api/FormulaBuilder/GetFormulaKeys",
  //Budget
  GetVendorListingBySpecId:
    BaseURL + "/api/VendorIndicativePricing/GetVendorListForSpecById",
  CreateBudgetInstance: BaseURL + "/api/CreateBudgetInstance",
  GetBudgetInstancesForBOQ: BaseURL + "/api/GetBudgetInstances",
  CreateBudget: BaseURL + "/api/Budget/Create",
  GetBudgetListByInstance: BaseURL + "/api/GetSpecVendorPriceConsolidated",
  UpdateBudgetInstance: BaseURL + "/api/UpdateBudgetInstance",
  GetFFNEBudgetDetailsProvManagement:
    BaseURL + "/api/Budget/GetFFnEBudgetDetailsProv?budget_id=",
  GetFFnEBudgetItemHistory:
    BaseURL + "/api/Budget/GetFFnEBudgetItemHistory?id=",
  GetFFnEBudgetInstanceDetails:
    BaseURL + "/api/GetFFnEBudgetinstanceDetails?id=",
  FFnEBudgetInstance: BaseURL + "/api/BudgetInstance",
  GetFFnEBudgetDetailsProv: BaseURL + "/api/Budget/GetFFnEBudgetDetailsProv",
  GetFFnEBudgetDetailsClient:
    BaseURL + "/api/Budget/GetFFnEBudgetDetailsClient",
  GetFormulasProv: BaseURL + "/api/FormulaBuilder?project_id=",
  GetBudgetHistory: BaseURL + "/api/Budget/GetFFnEBudgetItemHistory",
  SaveBudgetProv: BaseURL + "/api/Budget/SaveBudgetDetailsProv",
  SaveBudgetClient: BaseURL + "/api/Budget/SaveBudgetDetailsClient",
  FilterBudgetProv: BaseURL + "/api/Budget/FilterBudgetItemsProv",
  FilterBudgetClient: BaseURL + "/api/Budget/FilterBudgetItemsClient",
  SendBudgetForApproval: BaseURL + "/api/Budget/SendBudgetForApproval",
  UpdateBudgetStatus: BaseURL + "/api/Budget/UpdateBudgetApprovalStatus",
  DetailBudgetApproveLink: BaseURL + "/api/BudgetSummary/UpdateRFPBudgetStatus",
  DetailedBudgetComments: BaseURL + "/api/BudgetSummary/UpdateRFPBudgetComments",
  GetSummaryBudget: BaseURL + "/api/BudgetSummary?proj_id=",
  SendBudgetToClient: BaseURL + "/api/BudgetSummary/SendToClientRFPBudgetSummary",
  GetDetailedBudgetPackageListLink: BaseURL + "/api/BudgetDetails/GetBudgetRFPListForProjectProv?",
  GetDetailedBudgetPackageListClientLink: BaseURL + "/api/BudgetDetails/GetRFPBudgetsForProjClient?",
  DetailedBudgetFetchLink: BaseURL + "/api/BudgetDetails/GetDetailsBudget?",

  //Product Catalog
  GetCatalogItem: BaseURL + "/api/GetVendorCatalogProductsByVendorId?vendor_id=",
  SaveCatalogItem: BaseURL + "/api/SaveVendorCatalogProducts?vendor_id=",

  //Allowance Agreements
  AllowanceAgreementsGetLink: BaseURL + "/api/GetAllowanceTemplateList",
  AllowanceAgreementsPostLink: BaseURL + "/api/AllowanceAgreementTemplate",
  AllowanceAgreementsGetByIdLink: BaseURL + "/api/GetAllowanceAgreementTemplateDetails?id=",
  AllowanceAgreementsDeleteLink: BaseURL + "/api/DeleteMasterAllowanceTemplate?id=",
  AllowanceAgreementsUpdateLink: BaseURL + "/api/UpdateAllowanceAgreementTemplateDetails",
  //Color Codes
  ColorCodeLink: BaseURL + "/api/PageTabStatus?",

  //Open Items
  OpenItemLink: BaseURL + "/api/OpenItems?project_id=",

  //RFP
  VenderRfpPackageLink: BaseURL + "/api/RFP/GetRFPListForProject?",
  VendorDetailCostingFetchLink: BaseURL + "/api/RFP/GetRFPDetails?",
  VendorListByProject: BaseURL + "/api/RFP/GetVendorListForProject?proj_id=",
  References: BaseURL + "/api/References?ref_type=",
  GetRFP: BaseURL + "/api/RFP/GetLatestBoQForProject?proj_id=",
  SaveRFP: BaseURL + "/api/RFP/Create",
  UpdateRFP: BaseURL + "/api/RFP/Update",
  DeleteRFP:BaseURL+"/api/RFP/Delete?rfp_id=",
  VendorDetailCostingSaveLink: BaseURL + "/api/RFP/SaveRFPVendorDetails?",
  vendorDetailRFPDisplayValuesSaveLink: BaseURL + "/api/RFP/SaveRFPBidExComments?",
  VendorDetailCostingSubmitLink: BaseURL + "/api/RFP/SubmitVendorRFPToProv?",
  RFPSpecDocumentGetLink: BaseURL + "/api/RFP/SpecDocument",
  RFPSpecDocumentFetchLink: BaseURL + "/api/RFP/GetSpecDocument",
  GetRFPListForProject: BaseURL + "/api/RFP/GetRFPListForProjectProv?proj_id=",
  GetRFPDetail: BaseURL + "/api/RFP/GetRFPDetailsByIdProv?rfp_id=",
  RFPGetSupportingDocumentsLink: BaseURL + "/api/RFP/GetSupportingDocuments?",
  RFPSpecDeleteDocumentLink: BaseURL + "/api/RFP/DeleteSpecDocument?rfp_spec_id=",
  SaveIssueRFP: BaseURL + "/api/RFP/SaveIssueRFPDetails",
  ApproveRFP: BaseURL + "/api/RFP/SendRFPForApproval",
  SendRFPToVendors: BaseURL + "/api/RFP/SendRFPtoVendors?rfp_id=",
  RfpCount: BaseURL + "/api/RFP/GetRFPcountProv?proj_id=",
  GetVendorListForProv: BaseURL + "/api/RFP/GetVendorListForRFP?proj_id=",
  GetRFPListForProv: BaseURL + "/api/RFP/GetRFPListForProjectProvView?proj_id=",
  //ANALYSIS
  RFPListForProjectGetLink: BaseURL + "/api/Analysis/GetRFPProjectList?proj_id=",
  VendorComparisonRFPDetailsGetLink: BaseURL + "/api/Analysis/GetDetailVC?rfp_id=",
  DetailVCPostLink: BaseURL + "/api/Analysis/SaveDetailVC",
  GetSummaryVC: BaseURL + "/api/Analysis/GetSummaryVC?rfp_id=",
  GetRFPListForSummaryVC: BaseURL + "/api/Analysis/GetRFPVendorBidList?proj_id=",

  //Comments
  SaveComments: BaseURL + "/api/SaveComments?id=",

};

export default WebLinks;
